@import url('http://fonts.googleapis.com/css?family=Inter:300italic,400italic,700italic,300,400,700');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

:root {
  --left: 80px;
}

body {
  background: #f2f9f7;
}

body {
  /* text-transform: capitalize; */
  font-family: 'Inter' !important;
}

/* iframe {
  display: none !important;
} */

input:disabled {
  cursor: not-allowed;
}

.sidebar-top-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-content {
  background-color: #ffffff !important;
}

.modal-dialog {
  max-width: 1200px;
  margin: 30px auto;
}

.alert {
  /* color: #93232e;
    background-color: #fe7c965e; */
  height: 68px;
  border-width: 0.5px;
  /* border-color: #fe7c965e; */
  font-size: 0.875rem;
  padding: 0.7rem 1rem;
}

.alert .alert-heading {
  font-size: 1rem;
}

.alert p {
  font-size: 0.8rem;
}

.nav-tabs .nav-link {
  background: #214f8a;
  color: #f8f9fa;
  border-radius: 0;
  border: 1px solid #ebedf2;
  padding: 0.75rem 1.5rem;
  font-size: 0.8rem;
}

.donor-inventory-search_1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.sidebar .nav .nav-item.active .nav-link i {
  color: #ffffff;
  font-size: 2rem;
}

span.nav-link {
  padding: 0.5rem 0rem !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #ffffff;
  background: #214f8a;
  color: #214f8a;
  background-color: #ffffff;
  border-color: #ebedf2 #ebedf2 #ffffff;
}

.nav-pills .nav-link {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
  border-radius: 12px;
}

.nav-pills .nav-link.active {
  color: white;
  background-color: var(--primary);
}

.container_wraper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0rem;
  padding: 0px !important;
  overflow: hidden !important;
}

.container_left {
  width: 230px;
  margin: 16px;
}

.btn-gradient-primary {
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}

.gradient-btn {
  outline: none;
  background: linear-gradient(90.26deg, #214f89 0.17%, #12bcab 99.77%);
  color: #fff;
  border: none;
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  height: 37px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  min-width: 95px;
}

.gradient-btn_red {
  background: red !important;
  color: #fff !important;
}

.gradient-btn:hover {
  opacity: 0.8;
  color: #ffffff;
}

.backBtn {
  background: #e8f3f5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  min-width: 95px;
  height: 37px;
  border-radius: 8px;
  color: #214f8a;
}

.container_right {
  width: calc(100% - 280px);
  background: url('/public/assets/images/right-section.png');
  background-size: cover;
  background-position: center center;
  padding: 8px;
  overflow: hidden !important;
  margin-right: 0rem !important;
}

.sidebar-collapse .container_left,
.sidebar-collapse .sidebar {
  width: 80px !important;
}

.sidebar-collapse .container_right {
  width: calc(100% - 100px) !important;
}

.sidebar > nav > ul > li {
  padding: 0 !important;
}

.sidebar .nav .nav-item {
  padding: 0 1rem !important;
  margin: 0.25rem 0rem !important;
}

.sidebar-list-items > span {
  margin: 0 1rem;
}

.main-content {
  width: 100vw;
  padding-top: 86px;
}

.footer-section .nav-link {
  color: #fff;
  font-size: 2rem !important;
  padding: 1.2rem 0rem !important;
}

.footer-section .nav {
  padding-bottom: 0rem !important;
}

.sign-out i {
  color: #fff;
  font-size: 2rem !important;
}

.sidebar .nav .nav-item {
  padding: 0px 0.5rem !important;
  margin: 0.25rem 0rem !important;
}

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 270px);
}

.footer-section {
  color: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;

  margin: auto;
  padding-top: 0rem;
  bottom: 2rem;
  border-top: 2px solid white;
  /* background: linear-gradient(to right, #214f89, #12bcab); */
  z-index: 99;
}

.footer-section > hr {
  color: white;
  background: white;
  height: 1px;
  border-radius: 2px;
  margin: auto 0.2rem;
}

.btn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.sign-out {
  text-decoration: none;
  color: white !important;

  padding: 0.5rem !important;
  margin: 0rem 0rem !important;
  border-radius: 8px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.sign-out:hover {
  text-decoration: none;
  color: white;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.footer-note {
  padding: 0.2rem;
  font-size: 0.8rem !important;
  margin-bottom: 0rem !important;
}

.login-main-container {
  /* border: 2px solid rebeccapurple; */
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh !important;
  width: 100vw !important;
  top: 0;
  left: 0;
  right: 0;
  background: #edeff3;
}

.login-main-back-ground-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh !important;
  width: 100% !important;
  justify-content: center;
  /* background: url("/public/assets/images/loginbackground.png"), lightgray 50% / cover no-repeat;
  mix-blend-mode: multiply;
  background-size: 500px; */
}

.login-main-img-container {
  position: relative;
  width: 40% !important;
  min-width: 400px;
  height: 100vh !important;
  border-radius: 0px 16px 16px 0px;
  color: #fff;
  background-image: url('/public/assets/images/auth/loginBg.png');
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  mix-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/* .login-main-img-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/public/assets/images/auth/loginBg.jpeg");
  opacity: 0.3;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
} */

.login-main-content-container {
  height: 100vh !important;
  width: 60% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
}

.login-ui-container {
  max-width: 400px;
}

.login-main-img-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 100px;
  opacity: 1;
  z-index: 10;
}

.login-main-img-subheading {
  color: #fff;
  font-family: Prompt;
  font-size: 20.603px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
}

.login-main-img-bottom-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login-main-img-bottom {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

.login-custom-btn {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 50px;
  width: 100%;
}

.login-terms-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--grey-grey-1, #777);
  text-align: center;
  /* Paragraph / P12 */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-top: 4rem;
}

.terms-color {
  color: var(--grey-grey-1, #777);
}

.login-mob-bottom-container {
  display: none;
  flex-direction: column;
  font-size: 7px;
  color: white;
  margin: 12px;
  text-align: center;
}

.login-mob-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.login-mob-top {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login-uiform-container {
  padding: 8px 16px;
}

@media only screen and (max-width: 700px) {
  .login-main-img-container {
    display: none;
  }

  .login-mob-top {
    display: flex;
  }

  .login-main-container {
    display: block;
    overflow: auto;
    width: auto;
    height: auto;
  }

  .login-main-content-container {
    width: 100% !important;
    height: auto !important;
    background: linear-gradient(90deg, #214f89 0.17%, #12bcab 99.77%);
    overflow: auto;
    padding-left: 6px;
    padding-right: 6px;
  }

  .login-ui-container {
    border-radius: 8px;
    background: #fff;
  }

  .login-mob-bottom-container {
    display: flex;
  }
}

.login-page {
  /* border: 2px solid rebeccapurple; */
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh !important;
  width: 100vw !important;
  top: 0;
  left: 0;
  right: 0;
  background: #edeff3;
  z-index: 1000;
}

.tenant-user {
  /* border: 2px solid rebeccapurple; */
  border-radius: 8px;
  background: #214f8a40;
  margin: 0.5rem auto;
}

.tenant-user:hover {
  background: #214f8a40 !important;
  cursor: default !important;
}

.container_wraper {
  padding: 16px;
}

.nav-header {
  /* border: 2px solid rebeccapurple; */
  margin-top: 0rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  width: calc(100% - 300px);
}

.sidebar-collapse .nav-header {
  width: calc(100% - 130px);
}

.navbar-menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: 8vh !important;

  /* border: 2px solid rebeccapurple; */
}

.adhar-term-and-condition {
  height: 130px;
  overflow-y: scroll;
  font-size: 13px;
  line-height: 24px;
  word-spacing: 3px;
}

.mini-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto -1rem !important;
  padding: 0 !important;
}

.mini-logo {
  display: none;
}

.sidebar-collapse > {
  width: 80px;
}

.sidebar-collapse .banner-logo {
  display: none;
}

.sidebar-collapse .mini-logo {
  display: block;
}

.sidebar-collapse .sign-out > span {
  display: none;
}

.sidebar-collapse .menu-title {
  display: none !important;
}

.sidebar-collapse .footer-note {
  display: none;
}

.sidebar-collapse .sidebar-list-items,
.sidebar-collapse .logo-container,
.sidebar-collapse .sign-out {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.dashboard-main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 100%;
  /* border: 2px solid rebeccapurple; */
  /* padding: 0rem 1rem; */
}

.dashboard-card {
  width: 49%;
  margin: 0rem;
}

.search-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  width: max-content;
}

.page-header {
  margin-bottom: 0.9rem !important;
  margin-top: 0.5rem !important;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: auto;
  margin: auto;
}

.search-section > .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: auto;
  margin: auto;
}

.main-content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem auto;
}

.disease-list {
  display: grid;
  /* grid-template-columns: repeat(4, 1fr) !important; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80%;
  column-gap: 10%;

  /* border: 2px solid rebeccapurple; */
}

.disease-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  width: 80%;
  height: max-content;

  /* border: 2px solid rebeccapurple; */
}

.disease-item > .form-check-label {
  margin-bottom: auto;
  margin-top: 0;
}

.drop-down-menu-default {
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: 0.5s;
  transform-origin: left top;
  transform: scaleY(0);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.drop-down-menu-clicked {
  visibility: visible;
  height: auto;
  opacity: 100;
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.custom_drop_down {
  /* border: 2px solid rebeccapurple; */
  margin-left: 2.5rem;
  list-style-type: disc;
  color: #fff;
}

.custom_drop_down > li {
  margin: 0.5rem 0rem;
}

.custom_drop_down > li > a.active,
.custom_drop_down > li > a:hover {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.custom_drop_down > li > a {
  color: white;
  text-decoration: none;
  padding: 0.5rem;
  display: block;
}

.card-body-donor {
  height: 70vh;
}

.reg-choic {
  /* border: 2px solid rebeccapurple; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.mode-of-reg {
  /* border: 2px solid rebeccapurple; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 60vw;
}

.choice-result {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.abha_input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-right: auto;
}

.btn__cancel {
  color: #214f8a;
  background: #ecf4ff;
}

.full-page-wrapper {
  width: 100% !important;
}

.sideBar__custom {
  display: none;
}

.sidebar_new {
  display: none;
}

.sidebar_new {
  width: 250px;
  /*height: 100vh;
    */
  position: fixed;
  top: 0;
  left: -270px;
  z-index: 999;
  /*color: #fff;
    */
  transition: 0.5s;
  box-shadow: 3px 3px 3px rgba(51, 51, 51, 0.5);
  text-align: left;
}

.sidebar_new.active {
  left: 0;
}

/***** Content *****/

.content {
  width: 100%;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.nav-item.nav-profile .nav-link .nav-profile-image p {
  background: #e5e7eb;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  color: black;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  overflow: hidden;
}

.overflow_side {
  overflow-y: scroll;
}

/* .overflow_side::-webkit-scrollbar {
    display: none;
} */

.centerr {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centerr h5 {
  font-size: 3rem;
  font-weight: 800;
}

.icon__profile {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between !important;
}

.rotate_ChevronUp {
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.rotate_ChevronUp.chevrondown {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.drop-down-default {
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: fill 20ms cubic-bezire(0.4, 0, 0.2, 1) 20s;
  transform-origin: left top;
  transform: scaleY(0);
}

.drop-down-clicked {
  visibility: visible;
  height: 100%;
  opacity: 1;
  padding-left: 20%;
  transform: scaleY(1);
}

.auth_outer {
  border-radius: 2rem;
}

.overlay-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* height: 100%;
  width: 100%; */
  opacity: 0;
  transition: 0.3s ease;
  background-color: #214f8a;
}

.overlay__sync {
  position: fixed;
  left: 0;
  height: 100vh;
  width: 100vw;
  filter: blur(2px);
  display: grid;
  justify-content: center;
  -webkit-filter: blur(2px);
  z-index: 1200;
}

.overlay__main:hover .overlay-container {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.overlay__main:hover .overlay-container .overlay {
  opacity: 0.3;
}

.icon {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

.overlay__main:hover .icon {
  display: block;
}

.modal__titlediv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 1rem;
}

.input__filess {
  box-sizing: border-box;
  padding: 6px;
  display: flex;
  align-items: center;
  border: 1px dashed #7986cb !important;
  border-radius: 8px;
  font-family: 'Inter';
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #e9edf3;
}

.main_table_box {
  max-width: 800px;
  margin: auto;
}

.signature-position {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 3rem;
}

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 700px) {
  .container_left {
    display: none;
  }

  .sidebar_new {
    display: block;
  }

  .main-content {
    width: 100% !important;
  }

  .sideBar__custom {
    display: block;
  }

  .nav-header {
    margin: 0 !important;
    justify-content: space-between;
    align-items: center;
    padding: 0 auto;
    width: 96%;
  }

  .container_right {
    width: 100vw !important;
  }
}

@media screen and (max-width: 600px) {
  /* .sidebar-collapse .nav-header {
    width: calc(100% - 160px);
  } */

  .nav-header {
    width: 90% !important;
  }

  .gradient_back {
    width: 90% !important;
  }
}

@media screen and (max-width: 768px) {
  /* .sidebar-collapse .nav-header {
    width: calc(100% - 160px);
  } */

  .dashboard-main-content {
    flex-direction: column;
  }

  .dashboard-card {
    margin: 0.5rem;
    width: 100%;
  }

  .page-header {
    padding: 0rem 0rem;
    margin-bottom: 1rem !important;
  }

  .search-bar {
    /* border: 2px solid rebeccapurple; */
  }

  .search-section > .btn {
  }

  .main-content-header {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 20vh;
  }
}

/* width */
::-webkit-scrollbar {
  height: 10px;
}

::-webkit-scrollbar {
  width: 10px;
}

.no-scroll-height::-webkit-scrollbar {
  height: 0px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #6b72803b;
  height: 4px;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555555;
}

::-webkit-scrollbar-thumb:vertical:hover {
  background: #555555;
  width: 16px;
}

/* Handle on hover */
.table td,
.table thead th {
  text-transform: capitalize;
}

/* select.form-control {
  width: 100% !important;
} */

.table-responsive,
.table {
  transform: rotateX(180deg);
}

.react_table {
  transform: none !important;
  overflow: auto !important;
}

td {
  white-space: nowrap;
  min-height: 25px;
  position: relative;
  width: 90px;
}

.sort_img {
  height: 25px !important;
}

.long {
  overflow-x: hidden;
  width: 90px;
}

.extra_text {
  display: inline-block;
  max-width: 170px;
  text-overflow: ellipsis;
  cursor: pointer;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.2s linear;
}

.extra_text:focus,
/* .extra_text:hover {
  color: transparent;
} */

/* .extra_text:focus:after,
.extra_text:hover:after { */
.extra_text:focus:after {
  content: attr(data-text);
  /* overflow: visible; */
  text-overflow: inherit;
  /* background: #fff; */
  position: absolute;
  left: auto;
  top: auto;
  width: auto;
  max-width: 170px;
  /* border: 1px solid #eaebec; */
  padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: normal;
  /* word-wrap: break-word; */
  display: block;
  /* color: black; */
  /* margin-top: -1.25rem; */
  /*  z-index: 999; */
}

.table td {
  vertical-align: top;
}

/*model 2 css*/
.model-heading {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}

.model-body {
  margin-bottom: 24px;
}

/* ribbbon css starts */

.cr {
  width: 200px;
  padding: 6px;
  position: absolute;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #f0f0f0;
  z-index: 999;
}

.cr-left {
  left: -70px;
  bottom: 17px;
}

.cr-bottom.cr-left {
  transform: rotate(45deg);
}

.cr-orange {
  background: linear-gradient(90.26deg, #214f89 0.17%, #12bcab 99.77%);
}

.certificate__box {
  border: 1px solid;
  /* border-image: linear-gradient(90.26deg, #214F89 0.17%, #12BCAB 99.77%); */
  /* border-image-slice: 1; */

  border-radius: 8px;
  /* border: double 1px transparent; */
  background-image: linear-gradient(white, white), linear-gradient(90.26deg, #214f89 0.17%, #12bcab 99.77%);
  background-origin: border-box;
  background-clip: content-box, border-box;

  /* box-shadow: none; */
}

/* ribbbon css ends */

@media only screen and (max-width: 500px) {
  .search-input {
    margin-top: 7px;
    width: 100% !important;
  }

  /* .page-header {
    flex-direction: column;
    align-items: flex-start !important;
  } */
}

@media only screen and (max-width: 420px) {
  .search-input {
    margin-top: 7px;
    width:100% !important;
  }
}

.add-dnr-search input {
  /*width: 264px;*/
  width: 500px;
  font-size: 14px;
  margin: 0px;
  height: 37px;
}

.stretch-card,
.table_wrap {
  height: calc(100vh - 151px);
  overflow: hidden;
}

.scrollbar {
  border: 1px solid black;
  width: 100px;
  height: 300px;
}

.status-done {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;

  cursor: pointer;
}

.status-pending {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;

  cursor: pointer;
}

.status-pending {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;

  cursor: pointer;
}

.status-cancel {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;

  cursor: pointer;
}

.form-error-msg {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  color: #d32f2f;
}

.profile_progress {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  padding: 5px;
  /* border: 1px solid; */
  border-radius: 50%;
}

.profile_progress img {
  width: 93%;
}

.profile_progress .children {
  width: 93%;
}

.profile_progress .valuess {
  position: absolute;
  bottom: -4px;
  width: 2.5rem;
  height: 1.3rem;
  background: white;
  border: 2px solid #214f81;
  border-radius: 35px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background: #142f53 !important;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background: #142f53 !important;
}

/* width: 11px; */

.ps__thumb-y {
  background: #142f53 !important;
}

.ps__thumb-x {
  background: #142f53 !important;
}

.ps__rail-x {
  opacity: 0.6 !important;
}

.scroll_wrapper .ps__rail-y {
  opacity: 0.6 !important;
}

.btn:focus,
button:focus {
  /* outline: 1px solid black !important; */
  /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
}

.gradient-btn:disabled {
  background: var(--gray-gray-200, #d1d5db);
}

.gradient-btn:focus {
  background: var(--Secondary, #214f8a);
  color: #fff;
}

.btn-gradient-primary:focus {
  background: var(--Secondary, #214f8a);
  color: #fff;
}

.backBtn:focus {
  background: var(--Primary, #13bcab);
  color: #fff;
}

.gray_btn:focus {
  background: var(--Primary, #13bcab);
  color: #fff !important;
}

.text_next_line {
  word-break: break-word;
  white-space: initial !important;
}

/* center */

.nav_right {
  width: 11rem;
  height: 2.7rem;
  font-weight: 500;
  display: flex;
  background: transparent;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 5px 24px rgba(63, 81, 181, 0.1);
  color: #4b5563;
  border-radius: 8px;
  border: 1.5px solid #e3e5ef;
  padding: 0px 5px;
  text-transform: capitalize;
}

.nav_right img {
  max-width: 40px;
  height: 30px;
  object-fit: contain;
  max-height: 90%;
  border-radius: 4px;
}

.nav_right div {
  max-width: 40px;
  border-left: 1.5px solid #e3e5ef;
  display: grid;

  justify-content: center;
}

.navbar__btn {
  width: 10rem;
  height: 2.5rem;
  font-weight: 500;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 24px rgba(63, 81, 181, 0.1);
  color: #4b5563;
  border-radius: 8px;
  /*1*/

  border: none !important;
  position: relative;
}

.navbar__btn::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  /*1*/
  border: 1.5px solid transparent;
  /*2*/
  background: linear-gradient(90.26deg, #214f89 0.17%, #12bcab 99.77%) border-box;
  /*3*/
  -webkit-mask:
    /*4*/
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  /*5'*/
  mask-composite: exclude;
  /*5*/
}

.absolute_tog {
  position: absolute;
  top: 0;
  left: -48px;
}

.absolute_togle {
  position: absolute;
  top: 20px;
  z-index: 1201;
  right: -10px;
}

.input__filess:focus {
  outline: 1px solid black !important;
}

@media only screen and (max-width: 686px) {
  .absolute_tog {
    position: static;
  }
}

@media only screen and (max-width: 500px) {
  .absolute_tog {
    position: static;
  }

  .navbar__btn {
    width: 6.5rem;
    font-size: 10px;
    height: 2rem;
  }

  .nav_right span {
    display: none;
  }

  .nav_right {
    width: 6rem;
    height: 2.4rem;
    border-radius: 4px;
  }

  .home_iconn {
    display: none;
  }
}

.radio-btn-primary {
  accent-color: #214f8a;
}

.minimize_text {
  /* width: 80px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  display: block;
  min-width: 2rem !important;
  text-overflow: ellipsis !important;
  cursor: pointer;
  word-break: break-all;
  overflow: hidden !important;
  white-space: nowrap;
  transition: all 0.2s linear;
}

.edit-btn {
  color: #009283 !important;
  font-family: 'Inter';
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.p1 label {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.self_reg {
  height: calc(100vh - 195px) !important;
}

@media only screen and (max-width: 500px) {
  .self_reg {
    height: calc(100vh - 275px) !important;
  }
}

.input-small{
  width:'20px'
}
.input-big{
  width:'150px'
}

.custom-height{
  height:37px !important
}

.inputSize{
  font-size: 14px;
}

.request_header {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.request_header h6 {
  color: var(--ehr-secondary-d-0, #214f8a);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px !important;
}

.tab {
  display: flex;
  padding: 5px;
  white-space: nowrap;
  align-items: center;
  border-radius: 50px;
  background: #dadddd;
}

.tablinks {
  width: 130px;
  max-height: 38px;
  border-radius: 50px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ehr-secondary-d-0, #214f8a);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  z-index: 1;
}

.tablinksactive {
  z-index: 2;
  color: #ffffff !important;
  background: linear-gradient(90.26deg, #214f89 0.17%, #12bcab 99.77%);
}

.loader_syncp {
  text-decoration: none;
  white-space: nowrap;
  height: auto;
  overflow: hidden;
  width: 12em;
  animation: type 3s steps(60, end) infinite;
  margin-bottom: 0px;
}

@keyframes type {
  from {
    width: 0;
  }
}

.modal-footer-1 {
  position: absolute !important;
  bottom: 0 !important;
  background-color: #fff !important;
}

.modal-footer-1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.6875rem;
  border-top: 1px solid #ebedf2;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.text-ellipsis {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 2px;
}

.sidebar-collapse .hidetext {
  display: none !important;
}

.text_long {
  max-width: 100%;
  white-space: normal;
}

.label-blood {
  color: var(--Secondary, #214f8a);
  font-family: 'Inter';
  font-size: 100px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-wrapper {
  color: var(--gray-gray-300, #9ca3af);
  font-family: 'Inter-SemiBold', Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
}

.tab-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: #f2f9f7;
}

/* width */
::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 5px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.cursor-auto {
  cursor: auto !important;
}

.custom-scroll-arrow {
  display: block;
}

@media only screen and (min-width: 1800px) {
  .custom-scroll-arrow {
    display: none;
  }
}

.danger-table-row {
  background: #f5e8e8 !important;
  color: #ad1e1e !important;
  border: 1px solid #fff;
}

.react_player iframe {
  display: block !important;
}

.stick_pagination {
  position: sticky;
  top: -18px;
  background: #fff;
  z-index: 1;
}

.end_flexx {
  align-items: flex-end !important;
}

.div_ceneter {
  display: grid;
  height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.div_ceneter h1 {
  font-weight: 200;
}

.setting_new {
  flex-direction: column;
  align-items: flex-start !important;
}

.tab-container-gen {
  display: flex;
  width: 100%;
  padding: 8px 0px;
  gap: 16px;
  background: #f3f9f7;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.tab-gen-option-btn {
  display: flex;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 8px;
  font-family: Inter;
  border: #f3f9f7;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  color: #6181aa;
  background: #f3f9f7;
  gap: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.tab-gen-option-btn-active {
  border: #214f8a !important;
  background: #214f8a !important;
  color: #ffffff !important;
}

.tab-gen-pendings {
  padding: 4px 6px;
  border-radius: 4px;
  background: #214f8a;
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.tab-gen-pendings-active {
  background: #ffffff26;
}

.table-primary-key-main {
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #214f8a;
}

.table-primary-key-normal {
  color: #1b2124 !important;
}

.table-secondary-key-main {
  display: flex;
  align-items: center;
  gap: '4px';
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: #6b7280;
  line-height: 24px;
}

.icontab-container {
  width: 60%;
  overflow-x: auto;
}
@media (max-width: 768px) {
  .icontab-container {
    width: 100%;
  }
}

@media (min-width:1550px) {
  .icontab-container {
    width:75%;
  }
}

.chip-button {
  border-radius: 50px;
  padding: 8px;
  font-size: 12px;
  min-width: 150px;
  background: linear-gradient(89.74deg, #204e89 0.24%, #12b9aa 99.78%);
  border: none;
  color: #ffffff;
}

.chip-button-pending {
  background: #c86307 !important;
}

.gen-detail-withbg {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  padding: 16px 8px;
  border-radius: 8px;
  background: #e9edf3;
}

.he-40px {
  height: 40px !important;
}

#bottleImage {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  /* background-color: #F2F9F7; */
  padding-top: '16px';
  height: fit-content;
}

@media only screen and (max-width: 900px) {
  #bottleImage {
    display: none;
  }
}
.chip-button-abort {
  background: #dc3545 !important;
  cursor: not-allowed !important;
}

.selected-bags-info {
  padding: 5px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  margin: 4px 0px 0px 0px;
  font-weight: bold;
  font-size: 12px;
  border-radius: 8px 8px 0px 0px;
}
.linkableCss{
  cursor: pointer;
  color:#214f81;
  text-decoration: underline;
  font-weight: 600;
}
