.blood-grouping-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.qmark {
  position: absolute;
  right: 20px;
  top: 42px;
}

.detail-header {
  margin-top: 10px;
}

.blood-req-body {
  font-size: 14px;
}

.blood-requisition-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.add-requisition-btn-table {
  display: flex;
  padding: 7.427px 11.141px;
  justify-content: center;
  align-items: center;
  gap: 7.427px;
  border-radius: 7.427px;
  background: var(--ehr-secondary-l-8, #d3dce8);
  color: #214f8a;
}

.empty-requisition-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.add-requisition-msg {
  display: flex;
  width: 320px;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-gray-400, #6b7280);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.282px;
}

.ch-hdr {
  min-width: 10px;
  padding: 0;
}

.bloodcamp-tr th {
  min-width: 20px;
  vertical-align: top !important;
}

.btn-mn {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
}

.frm-time {
  content: "From :";
  border-radius: 8px;
  padding: 2px 5px;
  font-size: 14px;
}

.to-time {
  border-radius: 8px;
  padding: 2px 5px;
  font-size: 14px;
}

.to-time:not(:valid):before {
  content: "To :";
  margin-right: 20.87px;
  color: #9d9d9d;
  border-radius: 8px;
}

.frm-time:not(:valid):before {
  content: "From :";
  margin-right: 3px;
  color: #9d9d9d;
}

.checkbox-container-2 {
  margin-left: 50px;
  display: flex;
  gap: 120px;
  flex-wrap: wrap;
}

.right-mr {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.left-mr {
  margin-left: 22px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.chk-conponents-container {
  display: flex;
  gap: 150px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.chk-conponents {
  display: flex;
  gap: 2px;
  align-items: center;
  justify-items: center;

  flex-wrap: wrap;
}

.chk-conponents label {
  margin: 0;
  padding: 0;
}

.form-container-blood-camp {
  margin-bottom: 20px;
}

.exp-dnr {
  display: flex;
  gap: 20px;
  margin: 15px 0;
  align-items: center;
}

.newfld-cont {
  display: flex;
  margin: 15px 0;
  margin-left: 150px;
}

.new-input {
  margin: 15px 0;
}

.inpt-fld-div {
  display: flex;
  align-items: center;
}

.additional-fld-wit-icon {
  display: flex;
  position: relative;
  align-items: center;
  gap: 2px;
  width: 200px;
}

.form-check {
  margin-top: 0;

  display: flex;
  align-items: center;
}

.form-check label {
  margin: 0;
}

.addfld-cncl-btn {
  position: relative;
  bottom: 10px;
}

.abc {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.div-additional {
  margin-left: 140px;
}

.add-more-cont {
  margin-left: 190px;
  color: blue;
  cursor: pointer;
}

.top-margin {
  margin-top: 15px;
}

.cncl-logo {
  margin-bottom: 10px;
  width: 52px;
}

.a {
  width: 24px;
  height: 24px;
}

.rightborder {
  margin-left: 10px;
  border-right: solid;
}

.fxd-width-max {
  min-width: 200px !important;
  white-space: initial !important;
  line-height: 1.5 !important;
}

.fxd-width-min {
  min-width: 170px !important;
  white-space: initial !important;
  line-height: 1.5 !important;
}

.qrimg-phone {
  width: 340.228px;
  height: 645px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-start;
}

.qr-descreption-body {
  color: #6b7280;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.qr-descreption-title {
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 600px;
}

.Qr-main-container {
  display: flex;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  background: #fff;
  flex-wrap: wrap;
  /* Shadow-1 */
  box-shadow: 0px 4px 24px 10px rgba(0, 0, 0, 0.06);
}

.qr-button-div {
  display: flex;
  padding: 16px 0px;
  align-items: flex-start;
  gap: 24px;
}

.qr-model-wraper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
  background-color: rgba(189, 189, 189, 0.9);
}

.qr-model-container {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  /* height:825.029px;    */
  height: 570px;
  flex-shrink: 0;
  background: #fff;
  overflow: hidden;
  z-index: 1201;
}

.qr-head {
  background: var(--ehr-background, #f2f9f7);
  position: relative;
  display: flex;
}

.qr-econnect-logo {
  width: 100px;
  height: 55px;
}

.qr-camp-logo {
  width: 180px;
  height: 80px;
}

.qr-top-logo-area {
  width: 120px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.qr-econnect-logo-text {
  color: #484848;
  font-family: Prompt;
  font-size: 7px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.qr-camp-name {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.qr-top-camplogo-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.qr-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qr-body-heading {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 11px;
  margin-bottom: 5px;
}

.camp-qr-generated {
  width: 365.57px;
  height: 310px;
  flex-shrink: 0;
  fill: #1f4168;
  position: relative;
}

.qr-footer-ehr-logo {
  width: 215px;
  height: 50px;
}

.qr-powered-by {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 15px;
  margin-bottom: 5px;
}

.qr-body-img {
  position: relative;
}

.qr-qr {
  position: absolute;
  top: 38px;
  left: 98px;
}

.qr-body-imgs {
  position: relative;
}

.err-fld {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  color: #d32f2f;
}

.mleft-12 {
  margin-left: 16px;
}

.st-pending {
  color: #214f8a;
  cursor: pointer;
}

.st-done {
  color: #11a99a;
}

.in-fld {
  border-radius: 8px;
  height: 47px;
  font-size: 1rem;
  margin-top: 2px;
}

.time-in-fld {
  max-height: 46px !important;
  border-radius: 8px;
  font-size: 1rem;
}

.time-flds {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.main_table_box {
  max-width: 800px;
  margin: auto;
}

.main_table_box table {
  width: 100%;
}

.main_table_box td {
  vertical-align: top;
}

.main_table_box b {
  font-weight: bold;
}

.main_table_box td {
  margin: 0;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.429;
  letter-spacing: 0.15px;
  color: rgba(76, 78, 100, 0.68);
}

.report-pre tbody tr td {
  border-right: 1px solid #e9edf3;
  border-bottom: 1px solid #e9edf3;
  height: 40px;
  font-family: "Inter";
  font-size: 0.8rem;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  font-weight: 500;
  padding: 5px;
}

@media only screen and (max-width: 776px) {

  /* STYLES HERE */
  .div-additional {
    margin-left: 0;
  }

  .add-more-cont {
    margin-left: 0;
    color: blue;
  }
}

@media only screen and (min-width: 576px) {
  .freez-column {
    position: sticky;
    right: 0;
    z-index: 2;
  }
}

@media only screen and (max-width: 450px) {
  .qr-model-container {
    width: 350px;
  }

  .qr-econnect-logo {
    width: 80px;
    height: 45px;
  }

  .qr-camp-logo {
    width: 150px;
    height: 70px;
  }
}

@media only screen and (max-width: 1300px) {
  .time-flds {
    flex-direction: column;
    align-items: start;
  }

  .time-lbl {
    min-width: 126px !important;
  }
}

.discount-chkbx {
  display: flex;
  flex-direction: column;
  height: 82.8px;
  padding-left: 20px;
  justify-content: center;
}

.camp-name {
  color: var(--tints-shades-p-light-10, #303f9f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  cursor: pointer;
}

.report_tablee thead {
  background-color: #e9edf3 !important;
}

.report_tablee thead th {
  vertical-align: middle !important;
  font-family: "Inter";
  font-size: 0.8rem;
  font-weight: 500;
  text-align: left;
  font-weight: 500;
}

.report_tablee tbody tr td {
  font-family: "Inter";
  font-size: 0.8rem;
  font-weight: 500;
  text-align: left;
}


.progress-label {
  color: var(--gray-gray-500, #4B5563);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 125% */
}

.progress-status-pending {
  cursor: pointer;
  color: var(--secondary-warning, #C86307);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 125% */
}

.progress-status-done {
  cursor: pointer;
  color: var(--ehr-primary-d-1, #11A99A);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 125% */
}


.progress-div-main {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.progress-bar {
  width: 160px;
  padding: 20px;
  height: 160px;
  color: var(--ehr-secondary-l-6, #A6B9D0);
  border-radius: 50%;
  background:
    radial-gradient(closest-side, #E9EDF3 79%, transparent 80% 100%),
    conic-gradient(#21538B 75%, #E9EDF3 0);

}

.progress-main-content-div {
  display: flex;
  flex-direction: column;
  background-color: #F2F9F7;
  padding: 10px 16px 10px 16px;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 8px;
}

.progress-lable {
  color: var(--gray-gray-700, #1F2937);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 171.429% */
}

.progress-value {
  color: var(--ehr-secondary-d-6, #07101C);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}
.attach-certificate{
  padding: 4px 8px;
  background-color:var(--ehr-secondary-d-0, #214F8A) ;
  color:#F2F9F7;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
}

.progress-msg {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px;
  background-color: #F2F9F7;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  color: var(--ehr-secondary-d-0, #214F8A);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  line-height: 24px;
  /* 150% */
}

.progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.progress-headings-content {
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  flex-wrap: wrap;
}


.progress-organizer-details {
  border-right: 1px solid #07101C;
  padding: 0 8px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.progress-subdiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}

.div-links{
  cursor: pointer;
  color: blue;
  font-weight: 600;
}

.mobile-progress{
  display: none;
  flex-wrap: nowrap;
padding: 4px;
align-items: flex-start;
gap: 8px;
border-radius: 21px;
background: var(--ehr-secondary-l-9, #E9EDF3);
overflow-x: auto;
}
.mobile-progress-items-active{
  display: flex;
  flex-wrap: nowrap;
padding: 4px 8px;
justify-content: center;
flex-shrink:0;
align-items: center;
gap: 5px;
border-radius: 31px;
background: var(--EHR-background, #F2F9F7);
}

.mobile-progress-items{
  display: flex;
padding: 4px 8px;
flex-wrap: nowrap;
flex-shrink:0;
justify-content: center;
align-items: center;
gap: 5px;
border-radius: 31px;
background: var(--ehr-secondary-l-9, #E9EDF3);
}


@media only screen and (max-width: 450px) {
  .qr-model-container {
    width: 350px;
  }

  .qr-econnect-logo {
    width: 80px;
    height: 45px;
  }

  .qr-camp-logo {
    width: 150px;
    height: 70px;
  }
  .mobile-progress{
    display: flex;
  }
  .full-progress{
    display: none;
  }
  .progress-div-main{
    flex-direction: column;
  }
  .profile-image-section{
    display: flex;
    justify-content: center;
  }
  .progress-headings-content{
    flex-direction: column;
  }
  .progress-subdiv{
    flex-direction: column;

  }
  .progress-organizer-details{
    border: none;    
    gap:8px;
  }
}
